import React from 'react';
import { Link } from 'react-router-dom'


// import Homeslider from '../components/sliders';
import TechnologySection from '../components/banner_lines';

const Hero3 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    

    return (
        <section className="business_consulting_hero_section section_decoration mb-0 bg-light" 
    >
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-5">
                        <div className="business_consulting_hero_content">
                            
                            <h1 className="typing">
                            Appshive
                            </h1>
                            <p>
                            The digital journey starts here
                                                        </p>
                            <Link onClick={ClickHandler} className="btn btn-primary" to="/">
                                <span className="btn_label2">Start with us</span>
                                <span className="btn_icon">
                                    <i className=" fa-solid fa-arrow-up-right"></i>
                                    <i className=" fa-solid fa-arrow-up-right"></i>
                                </span>
                            </Link>
                           
                        </div>
                    </div>
                    <div className="col-lg-6">
                    {/* <Homeslider /> */}
                    <TechnologySection/>

                    </div>
                </div>
            </div>
            {/* <div className="decoration_item shape_1">
                <img src={shape3} alt="Shape" />
            </div> */}
        </section>
    )
}

export default Hero3;