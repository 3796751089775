import React from 'react';

const Modal = ({ show, handleClose, children }) => {
    const modalStyles = {
        display: show ? 'flex' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    };

    const modalContentStyles = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '90%',
        maxWidth: '800px',
        position: 'relative',
    };

    const closeButtonStyles = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        fontSize: '20px',
        cursor: 'pointer',
    };

    return (
        <div style={modalStyles} onClick={handleClose}>
            <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
                <button style={closeButtonStyles} onClick={handleClose}>×</button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
