import React from 'react'
import { Link } from 'react-router-dom'

import Footeraddress from '../components/footeraddress';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const BusinessFooter = (props) => {
    return (
        <footer className="site_footer footer_layout_3 bg-white">
        <div className="footer_main_content ">
          <div className="container">
           

            <div className="row justify-content-lg-between pt-lg-4">
              
             
              <div className="col-lg-12">
                <div className="row">
                <div className="col-md-3 col-sm-6">
                    <div className="footer_widget">
                      <h3 className="footer_info_title">Connect with call </h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                        <img width="26" height="26" src="https://img.icons8.com/ios-filled/50/007553/apple-phone.png" alt="apple-phone"/>
                          <a href="tel:7799360999">
                            <span className="icon_list_text">
                            7799360999
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="footer_widget">
                      <h3 className="footer_info_title">Start a Conversation</h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                        <img width="26" height="26" src="https://img.icons8.com/ios-filled/50/007553/apple-mail.png" alt="apple-mail"/>
                          <a href="mailto:info@emobomo.com">
                            <span className="icon_list_text">
                            info@emobomo.com
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-6">
                <div className="footer_widget">
                {/* <h3 className="footer_info_title">Our Office Locations</h3> */}
                <Footeraddress/>
                 
                </div>
              </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="footer_bottom bg-primary text-white">
          <div className="container d-md-flex align-items-md-center justify-content-md-between">
            <p className="copyright_text m-0">
              Copyright © 2024 Appshive, All rights reserved.
            </p>
            <p className="copyright_text m-0">
              Developed by <Link onClick={ClickHandler} to="/" target="_blank">Emobomo</Link>
            </p>
          </div>
        </div>
      </footer>
    )
}

export default BusinessFooter;