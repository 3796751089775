import React, { Fragment } from 'react';
import Header from '../components/header';
import Hero from '../components/banner';
// import FeaturesSection from '../components/about';
// import EmpoweringSuccess from '../components/clients';
// import ConsultingService from '../components/services';
// import BusinessTestimonial from '../components/testimonials';

import Footer from '../components/footer';
import Scrollbar from '../components/scrollbar';


const HomePage = () => {

    return (
        <Fragment>
            <div className='index_business_consulting'>
                <main className="page_content s2">
                    <Header />
                    <Hero/>
                    {/* <FeaturesSection />
                    <ConsultingService />
                    <BusinessTestimonial /> */}
                   
                    {/* <BusinessContact /> */}
                    {/* <EmpoweringSuccess /> */}
                    <Footer />
                    <Scrollbar />
                </main>
            </div>
        </Fragment>
    )
};
export default HomePage;