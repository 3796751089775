// import React, { useState } from 'react';
import { Link } from "react-router-dom";


import sImg1 from '../images/statistics.png'
import sImg2 from '../images/crm.png'
import sImg3 from '../images/coding.png'
import sImg4 from '../images/responsive-design.png'
import sImg5 from '../images/chat.png'
import sImg6 from '../images/social-media.png'

const TechnologyList = [
    {
        Id: '1',
        sIcon: sImg1,
        title: 'ERP',
        slug: 'erp',
    },
    {
        Id: '2',
        sIcon: sImg2,
        title: 'CRM',
        slug: 'crm',
    },
    {
        Id: '3',
        sIcon: sImg3,
        title: 'Website Development',
        slug: 'website-development',
    },
    {
        Id: '4',
        sIcon: sImg4,
        title: 'UX/UI Design',
        slug: 'ux-ui-development',
    },
    {
        Id: '5',
        sIcon: sImg5,
        title: 'APP Development',
        slug: 'app-development',
    },
    {
        Id: '6',
        sIcon: sImg6,
        title: 'Digital Market',
        slug: 'digital-market',
    },
]


const TechnologySection = (props) => {

    // const [activeTab, setActiveTab] = useState('1');

    // const toggle = tab => {
    //     if (activeTab !== tab) setActiveTab(tab);
    // }
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (

        <div className="section_space">
            {/* <div className="heading_block text-center">
                <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `` }}>
                    Our Technologies
                </div>
                <h2 className="heading_text mb-0">
                    We Use <mark>Technologies</mark>
                </h2>
            </div> */}

            <div className="tab_block_wrapper">
                
                        <div className="web_development_technologies row justify-content-center">
                            {TechnologyList.slice(0, 12).map((technology, cnt) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-6" key={cnt}>
                                    <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                                    <Link onClick={ClickHandler} to={`/`}>
                                        <div className="iconbox_icon">
                                            <img src={technology.sIcon} alt="" />
                                        </div>
                                        <div className="iconbox_content">
                                            <h3 className="iconbox_title mb-0">{technology.title}</h3>
                                        </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    
            </div>
        </div>
    )
}

export default TechnologySection;