import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import HomePage from './HomePage'

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
         
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
