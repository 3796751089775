import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

const ContactForm = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        cpassword: ''
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            setForms({
                name: '',
                email: '',
                phone: '',
                password: '',
                cpassword: ''
            });
            // alert('Form submitted successfully!');
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)}>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_name">Full Name</label>
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            className="form-control"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Full Name"
                        />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_email">Your Email</label>
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            className="form-control"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Email"
                        />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_phone">Your Phone</label>
                        <input
                            value={forms.phone}
                            type="text"
                            name="phone"
                            className="form-control"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Phone no"
                        />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_password">Password</label>
                        <input
                            value={forms.password}
                            type="password"
                            name="password"
                            className="form-control"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Password"
                        />
                        {validator.message('password', forms.password, 'required|min:6')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_cpassword">Confirm Password</label>
                        <input
                            value={forms.cpassword}
                            type="password"
                            name="cpassword"
                            className="form-control"
                            onBlur={changeHandler}
                            onChange={changeHandler}
                            placeholder="Confirm Password"
                        />
                        {validator.message('cpassword', forms.cpassword, `required|in:${forms.password}`, {
                            messages: { in: 'Passwords must match' }
                        })}
                    </div>
                </div>

                <div className="col-md-12 d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary pt-2 pb-2">
                        Submit
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
